<template>
  <v-container grid-list-md text-xs-center class="fundo align-center" fluid >
    <v-layout row wrap>
      <!-- LOGOTIPO -->
      <v-flex xl12 lg12 md12 sm12 xs12 class="cabecalho">
        <img
          src="../../assets/logo.svg"
          width="200px"
          alt="Enercred - Energia Limpa e Barata"
        />
      </v-flex>
      <v-flex xl6 lg6 md6 sm12 xs12 style="background: #FFF">
        <v-container grid-list-md text-xs-center>
          <v-layout row wrap>
            <v-container grid-list-md text-xs-center>
              <v-flex xs12 class="components hidden-sm-and-down">
                <img src="../../assets/img/save-money.jpg" width="25%" style="margin:10px"/>
              </v-flex>
              <!-- Mobile -->
              <v-flex class="components hidden-md-and-up">
                <h4 class="headline pa-3 text-md-center blue--text text--darken-3">Faça parte de uma comunidade que ajuda o planeta enquanto economiza na conta de energia</h4>
              </v-flex>
              <v-layout row wrap class="hidden-md-and-up">
                <v-flex pa-3 md6 xs12 class="components">
                  <cpnPasso2Economia :lead="lead" discount="0.15" :person_type="lead.person_type" color="#A4C425" plan="Cartão de Crédito"/>
                </v-flex>
                <v-flex pa-3 md12 xs12 class="components hidden-md-and-up">
                  <v-btn
                    class="hidden-md-and-up"
                    color="green"
                    block
                    round
                    large
                    href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!%20Gostaria%20de%20saber%20como%20economizar%20na%20luz%20da%20minha%20Empresa%20com%20a%20Enercred!&app_absent=0"
                    target="blank"
                    :loading="loading"
                    type="submit"
                    v-if="lead && lead.person_type === 'pj'"
                  >
                    Falar com um consultor
                    <v-icon right>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    class="hidden-md-and-up"
                    color="success"
                    @click.prevent="next(15)"
                    block
                    round
                    large
                    :loading="loading"
                    type="submit"
                  >Tornar-se membro</v-btn>
                </v-flex>
                <!-- <v-flex md6 xs12 class="components">
                  <cpnPasso2Economia :lead="lead" discount="0.10" color="grey" plan="Boleto"/>
                </v-flex> -->
                <!-- <v-flex md12 xs12 class="components hidden-md-and-up">
                  <v-btn
                    class="hidden-md-and-up"
                    color="success"
                    @click.prevent="next(10)"
                    block
                    large
                    :loading="loading"
                    type="submit"
                  >escolher plano 10</v-btn> -->
                <!-- </v-flex> -->
              </v-layout>
            </v-container>
            <v-flex pa-3 md12 xs12 class="hidden-sm-and-down">
              <cpnPasso2Vantagens/>
            </v-flex>
            <!-- <v-flex md12 xs12 class="components hidden-md-and-up">
              <v-btn
                class="hidden-md-and-up"
                color="secondary"
                @click.prevent="next"
                block
                large
                :loading="loading"
                type="submit"
              >QUERO CONTINUAR</v-btn>
            </v-flex> -->
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xl6 lg6 md6 sm12 xs12 class="hidden-sm-and-down">
        <v-container grid-list-md text-xs-center>
          <!-- <v-layout row wrap align-center style="height: 85vh;"> -->
          <v-layout align-center justify-center row fill-height style="height: 85vh;">
            <!-- <v-flex xs12 class="components">
              <img src="../../assets/img/save-money.jpg" width="50%" style="margin:50px"/>
            </v-flex> -->
            <!-- Web -->
            <!-- <v-layout class="hidden-sm-and-down" style="padding-top: 50px"> -->
              <v-flex md6 xs12 class="components">
                <cpnPasso2Economia :lead="lead" discount="0.15" color="#A4C425" plan="Cartão de Crédito"/>
                <v-flex xs12 class="components">
                  <v-btn
                    color="green"
                    block
                    round
                    large
                    href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!%20Gostaria%20de%20saber%20como%20economizar%20na%20luz%20da%20minha%20Empresa%20com%20a%20Enercred!&app_absent=0"
                    target="blank"
                    :loading="loading"
                    type="submit"
                    v-if="lead && lead.person_type === 'pj'"
                  >
                    Falar com um consultor
                    <v-icon right>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    color="success"
                    @click.prevent="next(15)"
                    block
                    large
                    round
                    :loading="loading"
                    type="submit"
                    v-else
                  >Tornar-se membro</v-btn>
                </v-flex>
              </v-flex>
              <!-- <v-flex md6 xs12 class="components">
                <cpnPasso2Economia :lead="lead" discount="0.10" color="grey" plan="Boleto"/>
                <v-flex xs12 class="components">
                  <v-btn
                    color="grey"
                    @click.prevent="next(10)"
                    block
                    large
                    :loading="loading"
                    type="submit"
                  >escolher plano 10</v-btn>
                </v-flex>
              </v-flex> -->
            <!-- </v-layout> -->
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
    <v-snackbar
      :color="color"
      :bottom="bottom"
      :top="top"
      :left="left"
      :right="right"
      v-model="snackbar"
      :timeout="timeout"
      dark
    >
      <v-icon
      color="white"
      class="mr-3"
      >
      mdi-bell-plus
      </v-icon>
      <div v-html="snackMessage"/>
      <v-icon
      size="16"
      @click="snackbar = false"
      >
      mdi-close-circle
      </v-icon>
    </v-snackbar>
  </v-container>
</template>
<script>
import { saveAnalytics } from "@/services/save_analytics"
import Passo2Economia from "../../components/order/Passo2Economia"
import Passo2Vantagens from "../../components/order/Passo2Vantagens"

// import { SET_CONVERSION } from "../../graphql/rdstation"
import { getLead, updateLead } from '../../services/firebase/lead'


export default {
  components: {
    cpnPasso2Economia: Passo2Economia,
    cpnPasso2Vantagens: Passo2Vantagens
  },
  data() {
    return {
      loading_inicio: true,
      loading: false,
      isProd: false,
      lead: {},
      color: null,
			colors: [
					'purple',
					'info',
					'success',
					'warning',
					'error'
			],
			top: true,
			bottom: false,
			left: false,
      right: false,
      timeout: 36000,
			snackMessage: '',
			snackbar: false,
    }
  },
  created() {
    this.loading_inicio = true
    this.isProd = process.env.NODE_ENV === "production"
    if(this.$route.query.email) {
      const email = this.$route.query.email
      getLead(email)
        .then(lead => {
          this.lead = lead
          this.loading = false
          this.loading_inicio = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading = false
          this.loading_inicio = false
        })
    } else {
      const lead = JSON.parse(localStorage.getItem("lead"))
      // console.log('lead: ', lead)
      if(lead && lead.email) {
        this.lead = lead
      }
      this.loading = false
      this.loading_inicio = false
    }
  },
  methods: {
    next(plan) {
      // analytics
      this.lead.step = 12
      this.lead.payment_type = plan===15?'credit_card':'boleto'
      updateLead(this.lead.email, {step: 12})
        .then(() => {
          localStorage.setItem("lead", JSON.stringify(this.lead))
          if (this.isProd) {
            const analytics = {
              eventCategory: "onboard",
              eventAction: "ACCEPTED-PLAN",
              eventLabel: "enercred",
              transaction_id: this.lead.email,
              value: this.lead.average_consumption,
              utm_source: this.lead.utm_source,
              utm_medium: this.lead.utm_medium,
              utm_campaign: this.lead.utm_campaign,
              utm_content: this.lead.utm_content,
              utm_term: this.lead.utm_term,
              person_type: this.lead.person_type
            }
            saveAnalytics(analytics)
            // const conversion = {
            //   event_type: "OPPORTUNITY",
            //   event_family: "CDP",
            //   payload: {
            //     email: this.lead.email,
            //     funnel_name: "default"
            //   }
            // }
            // this.setRDConversion(conversion)
          }
          this.$router.push("/assinatura/3")
        })
        .catch(err => {
          this.loading = false
          console.error("Error: ", err)
          this.snack('Desculpe, houve algum erro ao salvar, reiniciaremos o seu cadastro!', 'red', 'top')
          // alert("Desculpe, houve algum erro ao salvar, reiniciaremos o seu cadastro!")
          this.$router.push("cadastro")
        })
    },
    // setRDConversion(conversion) {
    //   return this.$apollo.mutate({
    //     // Query
    //     mutation: SET_CONVERSION,
    //     // Parameters
    //     variables: {
    //       event_type: conversion.event_type,
    //       event_family: conversion.event_family,
    //       payload: conversion.payload
    //     }
    //   })
    // },
    snack (message, color, ...args) {
			this.top = false
			this.bottom = false
			this.left = false
			this.right = false

			for (const loc of args) {
					this[loc] = true
			}

			this.color = color
			this.snackMessage = message
			this.snackbar = true
		},
  },
  mounted() {
    this.loading_inicio = false
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

.fundo {
  background-color: #FFF;
}
.btn-assinar {
  /* margin: 10px 20px 10px 20px; */
  font-weight: bold;
  font-size: 1.2rem;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #FFF;
}
template {
  background-color: #FFF;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.cabecalho {
  height: 70px;
  vertical-align: middle;
  display: table-cell;
  margin-top: 20px;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100vh;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 1% 5% 10% 5%;
  text-align: center;
  /* border-radius: 10px; */
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}

.components {
  margin: 5px 10px
}
</style>
